import { useState } from 'react';
import { Text, VStack, Input, RadioGroup, Stack, Radio, CheckboxGroup, Checkbox } from '@chakra-ui/react';
import { SubmitStepButton } from './SubmitStepButton';
import { useNotificationCentralContext } from '../notification-central.context';
import { useForm } from 'react-hook-form';
import { formatDate } from '../../../../utils';

export function ChooseDateStep({ onSubmit, HeaderComponent, colors }) {
  const [time, setTime] = useState('12:00');
  const [type, setType] = useState('one_time');
  const [daysOfWeek, setDaysOfWeek] = useState([
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ]);
  const { register, watch } = useForm({
    defaultValues: {
      date: formatDate(new Date()).toISOString().slice(0, 16),
    },
  });

  const date = watch('date');

  const { setFormData } = useNotificationCentralContext();

  const handleSubmit = () => {
    setFormData((oldState) => ({
      ...oldState,
      date,
      time,
      daysOfWeek,
      type,
    }));
    onSubmit();
  };

  return (
    <>
      <HeaderComponent title={'Quando será enviado'} />
      <VStack paddingY={2} alignItems={'flex-start'}>
        <Text fontWeight={'bold'}>Selecione abaixo a data de envio</Text>
        {/* @ts-expect-error */}
        <RadioGroup defaultValue="one_time" onChange={setType}>
          <Stack spacing={2} direction="column">
            <Radio value="one_time">Enviar em uma data especifica</Radio>
            <Radio value="recurring">Enviar de forma recorrente</Radio>
          </Stack>
        </RadioGroup>
        {type === 'one_time' ? (
          <Input
            type="datetime-local"
            {...register('date', { required: true })}
          />
        ) : (
          <>
            {/* @ts-ignore */}
            <CheckboxGroup value={daysOfWeek} onChange={setDaysOfWeek}>
              <Stack spacing={[1, 5]} direction={['column', 'row']}>
                <Checkbox value="monday">Segunda</Checkbox>
                <Checkbox value="tuesday">Terça</Checkbox>
                <Checkbox value="wednesday">Quarta</Checkbox>
                <Checkbox value="thursday">Quinta</Checkbox>
                <Checkbox value="friday">Sexta</Checkbox>
                <Checkbox value="saturday">Sábado</Checkbox>
                <Checkbox value="sunday">Domingo</Checkbox>
              </Stack>
            </CheckboxGroup>
            <Input type="time" value={time} onChange={(e) => setTime(e.target.value)} />
          </>
        )}
        <SubmitStepButton colors={colors} onSubmit={handleSubmit} />
      </VStack>
    </>
  );
}
