import React from "react";
import {
  AutocompleteInput,
  ImageField,
  ImageInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { ColorInput } from "react-admin-color-input";

export const LandingForm = (props) => (
  <SimpleForm {...props}>
    <ReferenceInput reference="agreement" source="agreement" label="Convênio">
      <AutocompleteInput optionName="name" />
    </ReferenceInput>
    <SelectInput
      label="Tipo"
      source="type"
      choices={[
        { id: "club", name: "Clube de Vantagens" },
        { id: "bookstore", name: "Biblioteca" },
      ]}
    />
    <TextInput
      required={true}
      source="slug"
      fullWidth
      label="Nome da Página (tudo em minúsculo)"
    />
    <ImageField source="phone_image" label="Logo Atual" />
    <ImageInput source="phone_image" label="Logo">
      <ImageField source="phone_image" />
    </ImageInput>
    <TextInput
      multiline
      fullWidth
      source="welcome_text"
      label="Texto da Home"
    />
    <ColorInput source="welcome_text_color" label="Cor do Texto da Home" />
  </SimpleForm>
);
