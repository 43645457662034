import React from "react";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  ImageField,
  ImageInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput
} from "react-admin";

export const BannerForm = (props) => (
    <SimpleForm {...props}>
        <BooleanInput source="active" label="Ativo" defaultValue={true} />
        <TextInput
            label="Nome"
            fullWidth
            source="name"
        />
        <SelectInput
            label="Tipo"
            source="type"
            defaultValue={"discount"}
            choices={[
                { id: "discount", name: "Desconto" },
                { id: "book", name: "Livro" },
            ]}
        />
        <ImageField source="image_mobile" label="Imagem Mobile Atual"/>
        <ImageInput source="image_mobile" label="Imagem Mobile">
            <ImageField source="image_mobile" title="Imagem Mobile" />
        </ImageInput>
        <ImageField source="image" label="Imagem Desktop Atual" />
        <ImageInput source="image" label="Imagem Desktop">
            <ImageField source="image" title="Imagem Desktop" />
        </ImageInput>
        <ReferenceInput label="Desconto" reference="discount" source="discount">
            <AutocompleteInput source="type" optionText={"id"} />
        </ReferenceInput>
        <ReferenceArrayInput label="Exclusivo do Convênios" reference="agreement" source="exclusive_agreements">
            <AutocompleteArrayInput source="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput label="Não mostrar para Convênios" reference="agreement" source="exclude_agreements">
            <AutocompleteArrayInput source="name" />
        </ReferenceArrayInput>
        <SelectInput
            label="Prioridade"
            source="priority"
            defaultValue={0}
            choices={[
                { id: 0, name: "Baixa" },
                { id: 1, name: "Média" },
                { id: 2, name: "Alta" },
            ]}
        />
    </SimpleForm>
);
