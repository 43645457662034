import {Resource, SelectInput, TextField} from "react-admin";
import {Apps} from "../../enums";
import {Book} from "@material-ui/icons"
import {fromFields} from "../../components/core/fromFields";
import {BookCategoryForm} from "./book_category/BookCategoryForm";
import {BookForm} from "./book/BookForm";
import {BookLevelField, BookTypeField} from "../../components/atoms/BookTypeField";
import {BookContributorForm} from "./book_contributor/BookContributorForm";
import {BookSectorForm} from "./book_sector/BookSectorForm";
import {BookActivityForm} from "./book_activity/BookActivityForm";
import { BookList } from "./book/BookList";

export const booksApp = [
    <Resource
        options={{label: "Mídia", app: Apps.book}}
        icon={Book}
        name="book"
        {...fromFields(BookForm, [
            {source: "id", field: TextField, label: "Id"},
            {source: "title", field: TextField, label: "Título"},
            {source: "level", render: <BookLevelField label={"Nível"}/>, input: <SelectInput alwaysOn="true" source="level" label="Nível"
            choices={[
                {id: "0", name: "Gratuito"},
                {id: "1", name: "Bronze"},
                {id: "2", name: "Prata"},
                {id: "3", name: "Ouro"},
                {id: "4", name: "Diamante"},
                {id: "5", name: "Light"}
            ]}/>, label: "Nível"},
            {render: <BookTypeField label="Tipo de Mídia"/>,
                input: <SelectInput alwaysOn="true" source="type" label="Tipo de Mídia"
                                    choices={[{id: "book", name: "Livro"}, {id: "magazine", name: "Revista"}, {id: "audiobook", name: "Audiobook"}]}/>,
                label: "Tipo de Mídia"
            }
        ])}
        list={BookList}
    />,
    <Resource
        options={{label: "Categoria de Mídia", app: Apps.book}}
        icon={Book}
        name="book_category"
        {...fromFields(BookCategoryForm, [
            {source: "name", field: TextField, label: "Nome"},
            {source: "sector", field: TextField, label: "Setor"},
            {render: <BookTypeField label="Tipo de Mídia"/>,
                input: <SelectInput alwaysOn="true" source="type" label="Tipo de Mídia"
                                    choices={[{id: "book", name: "Livro"}, {id: "magazine", name: "Revista"}, {id: "audiobook", name: "Audiobook"}]}/>
            }
        ])}
    />,
    <Resource
        options={{label: "Atividades de Mídia", app: Apps.book}}
        icon={Book}
        name="book_activity"
        {...fromFields(BookActivityForm, [
            {source: "name", field: TextField, label: "Nome"},
            {source: "sector", field: TextField, label: "Setor"},
            {render: <BookTypeField label="Tipo de Mídia"/>,
                input: <SelectInput alwaysOn="true" source="type" label="Tipo de Mídia"
                                    choices={[{id: "book", name: "Livro"}, {id: "magazine", name: "Revista"}]}/>
            }
        ])}
    />,
    <Resource
        options={{label: "Autores dos livros", app: Apps.book}}
        icon={Book}
        name="book_contributor"
        {...fromFields(BookContributorForm, [
            {source: "name", field: TextField, label: "Nome"},
            {source: "name_inverted", field: TextField, label: "Nome Invertido"},
            {source: "book", field: TextField, label: "Livro"},
        ])}
    />,
    <Resource
        options={{label: "Setores", app: Apps.book}}
        icon={Book}
        name="book_sector"
        {...fromFields(BookSectorForm, [
            {source: "name", field: TextField, label: "Nome"},
            {source: "type", field: TextField, label: "Tipo"},
        ])}
    />
]