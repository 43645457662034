import { BooleanField, DateField, NumberField, Resource, TextField } from "react-admin";
import { Apps } from "../../enums";
import { fromFields } from "../../components/core/fromFields";
import { VindiLogForm } from "./vindi_log/VindiLogForm";
import { NetworkCheck } from "@material-ui/icons";

export const integrationsApp = [
    <Resource
        id="vindilog"
        options={{ label: "Logs da Vindi", app: Apps.integrations }}
        icon={NetworkCheck}
        name="vindilog"
        {...fromFields(VindiLogForm, [
            { source: "id", field: NumberField, label: "ID" },
            { source: "user", reference: "user", field: <TextField source="full_name" />, label: "Usuário" },
            { source: "created_at", field: DateField, label: "Criado em" },
            { source: "error", field: BooleanField, label: "Erro" },
            { source: "handled", field: BooleanField, label: "Recebido" },
            { source: "fully_processed", field: BooleanField, label: "Processado" },

        ])}
    />

]