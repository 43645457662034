import { Datagrid, EditButton, Filter, ImageField, List, SelectInput, TextField, TextInput } from "react-admin";
import { BookLevelField, BookMonthFeaturedField, BookTypeField } from "../../../components/atoms/BookTypeField";

const BookFilter = (props) => (
  <Filter {...props}>
    <TextInput source="search" label="Titulo" alwaysOn  />
    <SelectInput source="month_featured" label={"Mês"} choices={[
                        { id: null, name: "" },
                        { id: 0, name: "Janeiro" },
                        { id: 1, name: "Fevereiro" },
                        { id: 2, name: "Março" },
                        { id: 3, name: "Abril" },
                        { id: 4, name: "Maio" },
                        { id: 5, name: "Junho" },
                        { id: 6, name: "Julho" },
                        { id: 7, name: "Agosto" },
                        { id: 8, name: "Setembro" },
                        { id: 9, name: "Outubro" },
                        { id: 10, name: "Novembro" },
                        { id: 11, name: "Dezembro" },
                    ]} alwaysOn />
    <SelectInput source="month_featured_year" label={"Ano"} choices={[
                        { id: 2023, name: "2023" },
                        { id: 2024, name: "2024" },
                    ]} alwaysOn />
    <SelectInput source="type" label={"Tipo"} choices={[
                        { id: "magazine", name: "Revista" },
                        { id: "audiobook", name: "Audiobook" },
                        { id: "book", name: "Livro" },
                    ]} alwaysOn/>                
    <SelectInput source="level" label={"Nível"} choices={[
                        { id: 0, name: "Gratuito" },
                        { id: 1, name: "Bronze" },
                        { id: 2, name: "Prata" },
                        { id: 3, name: "Ouro" },
                        { id: 4, name: "Diamante" },
                        { id: 5, name: "Light" },
                    ]} alwaysOn/>                
  </Filter>
);

export const BookList = (props) => {


  return (
    <List
      filters={<BookFilter />}
      sort={{ field: "month_featured", order: "DESC" }}
      title="Livros"
      {...props}
    >
      <Datagrid>
        <TextField source="id" label="ID" />
        <ImageField source="cover" label="Capa" />
        <TextField source="title" label="Título" />
        <BookLevelField label="Nível" {...props}/>
        <BookTypeField label="Tipo" {...props}/>
        <BookMonthFeaturedField label="Mês" {...props}/>
        <EditButton /> 
      </Datagrid>
    </List>
  );
};